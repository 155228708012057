import Vue from 'vue';

import BottomBar from './common/BottomBar';
import HeaderBar from './common/HeaderBar';
import ContactCustomService from './common/ContactCustomService';
import SignatureDialog from './SignatureDialog';
import ImageList from './ImageList';

Vue.component('BottomBar', BottomBar);
Vue.component('HeaderBar', HeaderBar);
Vue.component('ContactCustomService', ContactCustomService);
Vue.component('SignatureDialog', SignatureDialog);
Vue.component('ImageList', ImageList);