<template>
  <div class="my">
    <header-bar :title="$t('base_info_personal_info')" :hideBack="true" color="#ffffff"/>
    <div class="avatar-div">
      <el-avatar :size="50" :src="avatar" class="avatar"></el-avatar>
      <span class="mobile">{{ user.mobile }}</span>
      <span class="credit-score">{{ `${$t('credit_score')}: ${user.creditScore}` }}</span>
    </div>
    <van-cell-group inset class="menu" :border="false">
      <van-cell :title="$t('base_info_title')" is-link to="/baseInfoDetail">
        <template #icon>
          <img :src="baseInfoIcon"/>
        </template>
      </van-cell>
      <van-cell :title="$t('recharge')" @click="recharge" is-link>
        <template #icon>
          <img :src="walletIcon"/>
        </template>
      </van-cell>
      <van-cell :title="$t('contact_customer_service')" icon="service-o"
                @click="store.contactUs" v-if="store.showContact"/>
    </van-cell-group>
    <van-cell-group inset class="logout">
      <van-cell is-link @click="logout">
        <template #icon>
          <img :src="logoutIcon"/>
        </template>
        <template #title>
          <span class="custom-title">{{ $t('mine_logout') }}</span>
        </template>
      </van-cell>
    </van-cell-group>
    <bottom-bar current-name="My"></bottom-bar>
    <van-popup v-model="showRechargeAmt" :close-on-click-overlay=false class="recharge-div">
      <van-field v-model.number="rechargeParams.amt" clearable :placeholder="$t('input_recharge_amount')" type="number"
                 input-align="left" :required="true"/>
      <div class="confirm-recharge">
        <button class="cancel" @click="showRechargeAmt=false">{{ $t('common_cancel') }}</button>
        <button class="confirm" :class="{disabled:!rechargeParams.amt}"
                :disabled='!rechargeParams.amt' @click="confirmRecharge">{{ $t('common_confirm') }}
        </button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import avatar from '@/assets/avatar.png';
import baseInfoIcon from '@/assets/baseInfo-icon.png';
import walletIcon from '@/assets/wallet-icon.png';
import logoutIcon from '@/assets/logout-icon.png';
import store from "@/store";
import {authService, orderService} from "@/services";

export default {
  name: "PersonalInfo",
  data() {
    return {
      store,
      avatar,
      baseInfoIcon,
      walletIcon,
      logoutIcon,
      user: {},
      showRechargeAmt: false,
      loanProgress: null,
      rechargeParams: {
        amt: null,
      }
    }
  },
  mounted() {
  },
  activated() {
    this.getUserInfo();
    this.getLoanProgress();
  },
  methods: {
    async getLoanProgress() {
      this.loanProgress = await orderService.getLoanProgress();
    },
    async recharge() {
      if (!this.loanProgress || !this.loanProgress.status) {
        this.$toast(this.$t('loan_records_empty'))
        return;
      }
      this.showRechargeAmt = true;
    },
    async confirmRecharge() {
      let link = await orderService.recharge(this.rechargeParams);
      if (link) {
        this.showRechargeAmt = false;
        window.open(link, "_blank");
      }
    },
    async getUserInfo() {
      this.user = await store.getUser();
    },
    logout() {
      authService.logout();
      this.$router.push({name: 'login'});
    },
  }
}
</script>
<style lang="less" scoped>
.my {
  padding-top: 44px;
  padding-bottom: 51px;
  background-color: @base-color;
  min-height: calc(100% - 95px);

  .avatar-div {
    padding: 30px 0;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url("../../assets/home-header.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;

    .avatar {
    }

    .info-div {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      color: #FFFFFF;
      flex: 1;
      justify-content: space-between;
    }

    .mobile {
      margin: 10px 0 12px 0;
      font-size: 16px;
    }

    .credit-score {
      font-size: 16px;
      padding: 5px 20px;
      background: #FFFFFF26;
      border-radius: 15px;
    }

    padding-bottom: 30px;
  }
  .van-cell-group {
   background-color: #ffffff;
  }
  .menu {
    margin-top: 20px;
    .van-cell {
      margin-bottom: 10px;
    }
  }

  .van-cell {
    background-color: #ffffff;
    border-radius: 10px;

    img {
      height: 20px;
      margin-right: 10px;
    }
  }

  .logout {
    margin-top: 30px;
  }

  .recharge-div {
    .confirm-recharge {
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 10px 20px;

      .cancel, .confirm {
        background-color: rgb(41, 105, 255);
        color: #ffffff;
        border-radius: 5px;
        box-shadow: none;
        border: none;
        font-size: 13px;
        padding: 5px 10px;

        &.disabled {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>