<template>
  <div class="img-div">
    <template v-for="(item, index) of imgList">
      <img class="img-item" :src="item.img" :key="index"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ImageList',
  data() {
    return {
      imgList: [{
        img: require("@/assets/img/img.png")
      },{
        img: require("@/assets/img/img_1.png")
      },{
        img: require("@/assets/img/img_2.png")
      },{
        img: require("@/assets/img/img_3.png")
      }],
    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.img-div {
  display: flex;
  margin: 0 20px 0;
  justify-content: space-between;
  .img-item {
    width: calc(25% - 5px);
  }
}
</style>
